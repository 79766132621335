import React from "react"
import Header from "../components/header"
import * as H from "../components/headings"
import * as Text from "../components/text"
import * as C from "../components/containers"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const data = useStaticQuery(graphql`
      query {
          file(relativePath: { eq: "About.png" }) {
              childImageSharp {
                  fixed(width: 200) {
                      ...GatsbyImageSharpFixed
                  }
              }
          }
      }
  `)

  return (
    <div>
      <Header/>
      <C.centered>
        <H.h1>About The Artist</H.h1>
      </C.centered>
      <C.bioText>
        <img style={{float: "left", paddingRight: "8px"}} src={data.file.childImageSharp.fixed.src}
             alt="Artist & child viewing gallery"/>
        <Text.p>I grew up in London where I first started to carve out my career as an artist, musician and curator.
          While learning my trades at art school, I moved north and now live and work in Cumbria, where I also picked
          up
          the additional careers of mother, wife, fellwalker and retailer. I draw inspiration from the scenery,
          wildlife
          and characters of the surroundings of the nearby Lake District, though the subjects and meanings within my
          work are not limited to this.</Text.p>
        <Text.p>Recent projects have been paper-based art that ranges from
          collage pieces to bookbinding to
          hand-lettering, to illustrations and sketches using inks and charcoal, even biro. Through this range of media,
          I try and convey the raw energy of nature, humour, and the bold dynamism and enjoyment of making marks upon a
          page.</Text.p>
        <Text.p>I’ve dabbled in digital media, on occasion teaming my music with film. Overall though, my favourite
          thing is
          to put pencil to paper. I’m constantly learning new crafts and techniques. Future ambitions include one day
          creating a book for children and finding new and better ways to connect my art with my music and curatorial
          ideas.</Text.p>
        <Text.p>In fact, I think that life can be almost entirely mapped out and understood through connections.
          Connections
          between natural and man-made worlds, people and other animal species, past and present, conscious and
          subconscious and all those spaces in between. I hope that my art helps plot some of these points and indeed
          connects with you in some way. If it does, then get in touch, let me know. I so love finding out more about
          people and why art moves them.</Text.p>
      </C.bioText>
    </div>
  )
}